
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonContent,
  IonSpinner,
  /*IonText,
  IonCard,
  IonCardTitle,
  IonCardHeader,
  IonCardContent,
  IonLabel,
  IonList,
  IonItem,*/
  IonBadge,
  IonButton,
  /*   IonRow,
  IonCol, */
  IonIcon,
  modalController,
} from "@ionic/vue";
import { add, chevronForward } from "ionicons/icons";

import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import moment from "moment";

import apiTicket from "../services/ticket";
import apiMateriali from "../services/materiali";
import { openToast } from "../services/toast";

import Modal2 from "../components/Modal2.vue";

export default {
  name: "TicketDetail",
  components: {
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
    IonContent,
    IonPage,
    IonSpinner,
    /*IonText,
    IonCard,
    IonCardTitle,
    IonCardHeader,
    IonCardContent,
    IonLabel,
    IonList,
    IonItem,*/
    IonBadge,
    IonButton,
    /*     IonRow,
    IonCol, */
    IonIcon,
  },
  setup() {
    const route = useRoute();
    const id = route.params.id as string; //ticket_id by route params
    const loading = ref<boolean>(false);
    const ticket = ref([]);
    const materiali = ref([]);
    const userID = JSON.parse(localStorage.getItem("userInfo")).users_id;
    const router = useRouter();

    const created = ref(false); //flag per disabilitare button crea intervento nel caso in cui utente torni indietro da dettaglio intervento
    //const localInterventi = JSON.parse(localStorage.getItem("interventi"));

    /**
     *
     * Open modal to create new intervento
     *
     */
    async function openModal() {
      const modal = await modalController.create({
        component: Modal2,
        componentProps: {
          cliente: ticket.value["projects_customer_id"],
          impianto: ticket.value["tickets_project_id"],
          ticket: id,
        },
      });
      modal.onDidDismiss().then((detail) => {
        if (detail.data != undefined) {
          //Disable Crea intervento button if user go back from the newly created intervento
          created.value = true;
          openToast("Nuovo intervento inserito", "success");
          //console.log("id intervento creato: ", detail.data.tickets_reports_id);
          router.push(`/tabs/tab3/${detail.data.tickets_reports_id}`);
        }
      });
      return modal.present();
    }

    /**
     *
     * Print name and last name or customer company based on condition
     *
     */
    const riferimentoCliente = computed(() => {
      return (ticket) => {
        if (ticket.customers_name && ticket.customers_last_name) {
          return `${ticket.customers_name} ${ticket.customers_last_name}`;
        } else {
          return `${ticket.customers_company}`;
        }
      };
    });

    /**
     *
     * Set correct background for ticket status
     *
     */
    const statoTicket = computed(() => {
      return (statusId) => {
        let className = "";
        if (statusId == 1) {
          //aperto
          className = "danger";
        } else if (statusId == 2) {
          //in lavorazione
          className = "dark";
        } else if (statusId == 3) {
          //preso in carico
          className = "warning";
        } else if (statusId == 5) {
          //chiuso
          className = "success";
        }
        return className;
      };
    });

    /**
     *
     * Return '-' if field is null
     *
     */
    function checkField(field) {
      if (field == null || field == "") {
        return "-";
      } else {
        return field;
      }
    }

    /**
     *
     * Return '-' if tecnici is null
     *
     */
    function checkTecnici(tecnici) {
      if (tecnici == null || tecnici == "") {
        return "-";
      } else {
        return tecnici;
      }
    }

    /**
     *
     * Remove html tag from ticket description
     *
     */
    function parseHtmlEnteties(str) {
      const strippedString = str.replace(/(<([^>]+)>)/gi, "");
      return strippedString.replace(
        /&#([0-9]{1,3});/gi,
        function (match, numStr) {
          const num = parseInt(numStr, 10); // read num as normal number
          return String.fromCharCode(num);
        }
      );
    }

    /**
     *
     * Format date from YYYY/MM/DD HH:mm:ss to DD/MM/YYY
     *
     */
    function dateFormat(date) {
      if (date && moment.isDate(new Date(date))) {
        return moment(date).format("DD/MM/YYYY");
      } else {
        return "-";
      }
    }

    function getMateriali(codice_commessa: string) {
      apiMateriali
        .getMateriali(codice_commessa)
        .then((response) => {
          //console.log(response);
          materiali.value = response;
        })
        .catch((error) => {
          //error.value = true;
          openToast("Errore durante la richiesta dei materiali", "danger");
          console.error(error);
        })
        .finally(() => {
          //console.log("fine");
        });
    }

    onMounted(() => {
      //GET TICKET DATA
      loading.value = true;
      apiTicket
        .getTicketDetail(id, userID)
        .then((response) => {
          //console.log(response);
          ticket.value = response;
          //if ticket status is lavoro completato disable button
          if (ticket.value["tickets_status_id"] == "5") {
            created.value = true;
          }
          //getMateriali(response.tickets_project_id);
        })
        .catch((error) => {
          //error.value = true;
          openToast("Errore durante la richiesta del ticket", "danger");
        })
        .finally(() => {
          loading.value = false;
          //console.log("fine");
        });
    });

    return {
      loading,
      ticket,
      riferimentoCliente,
      dateFormat,
      checkField,
      parseHtmlEnteties,
      add,
      statoTicket,
      //Handle new intervento
      openModal,
      //flag for disabling button
      created,
      //New field after figma layout
      chevronForward,
    };
  },
};
